(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('MyBoardController', MyBoardController);

    MyBoardController.$inject = ['$scope', '$rootScope', '$filter', 'Principal', '$state',
        'Chamado', 'Sprint', 'SolicitacaoDesenvolvimento', 'Account', 'Modulo', 'User'];

    function MyBoardController($scope, $rootScope, $filter, Principal, $state,
                               Chamado, Sprint, SolicitacaoDesenvolvimento, Account, Modulo, User) {
        var vm = this;
        vm.modulos = Modulo.queryByUser();
        vm.load = load;
        vm.recuperarTickets = recuperarTickets;
        vm.buscarTarrefasDaSprint = buscarTarrefasDaSprint;
        vm.getQuantidadeTickets = getQuantidadeTickets;
        vm.openCalendar = openCalendar;

        vm.totalHoraTrabalhadas = 0;
        vm.totalTarefasTrabalhadas = 0;
        vm.totalTarefasAjudadas = 0;
        vm.users = User.getAllWithEquipe();
        vm.concluidas = [];
        vm.rejeitadas = [];
        vm.datePickerOpenStatus = {};
        vm.situacoesSprintSelecionadas = [null, "EM_ANDAMENTO"];

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getQuantidadeTickets(situacao) {
            var quantidade = 0;
            angular.forEach(vm.tarefas, function (tipo) {
                angular.forEach(vm.tarefas[tipo], function (ticket) {
                    if (ticket.situacao == situacao) {
                        quantidade = quantidade + 1;
                    }
                });

            });
            return quantidade;
        }


        function load() {
            Sprint.query({}, function (data) {
                vm.sprints = data;
                vm.sprint = vm.sprints[1];
                Principal.identity().then(function (account) {
                    vm.account = account;
                    vm.filtro = {};
                    vm.filtro.user = vm.account;
                    buscarTarrefasDaSprint();

                });
            });

        }

        function buscarTarrefasDaSprint() {
            vm.filtro.inicio = vm.sprint.inicio;
            vm.filtro.fim = vm.sprint.fim;
            var consulta = {
                idUsuario: vm.filtro.user.id,
                situacoesSprint: vm.situacoesSprintSelecionadas
            }
            SolicitacaoDesenvolvimento.getAllEmDesenvolvimento(consulta, function (data) {
                vm.tarefasDaSprint = data;
                vm.tarefasRejeitadas = [];
                vm.tarefasFazendo = [];
                vm.tarefasImpedidas = [];
                vm.tarefasAtrasadas = [];
                for (var i = 0; i < data.length; i++) {
                    var tarefa = data[i];
                    if (tarefa.situacao === 'FAZENDO') {
                        vm.tarefasFazendo.push(tarefa);
                    }
                    if (tarefa.situacao === 'REJEITADO') {
                        vm.tarefasRejeitadas.push(tarefa);
                    }
                    if (tarefa.situacao === 'IMPEDIDO') {
                        vm.tarefasImpedidas.push(tarefa);
                    }
                    if (tarefa.sprintChamado && tarefa.sprint !== vm.sprint.descricao) {
                        vm.tarefasAtrasadas.push(tarefa);
                    }
                }
                recuperarTickets();
            });
        }

        function recuperarTickets() {
            vm.mostrarTarefas = vm.tarefasDaSprint.length > 0;
            vm.tarefas = {};
            vm.todasTarefas = [];

            vm.chamados = [];
            vm.horasPorModulo = [];
            vm.horasPonto = [];
            vm.atividades = [];
            if (!vm.filtro.user.imagem) {
                Account.getImagemAjustadaByLogin({login: vm.filtro.user.login}, function (data) {
                    vm.filtro.user.imagem = data.imagemAjustada;
                });
            }
            recuperarHorasApontada();
        }

        function adicionarTarefa(atividade) {
            if (atividade.solicitacaoDesenvolvimento) {
                if (atividade.tipo === 'CODE_REVIEW') {
                    return;
                }
                var tem = false;
                for (var i = 0; i < vm.tarefasDaSprint.length; i++) {
                    var tarefaDaSprint = vm.tarefasDaSprint[i];
                    if (tarefaDaSprint.id === atividade.solicitacaoDesenvolvimento.id) {
                        tem = true;
                        if (!tarefaDaSprint.tempoGasto) {
                            tarefaDaSprint.tempoGasto = 0;
                        }
                        tarefaDaSprint.tempoGasto += atividade.tempo;
                        break;
                    }
                }
                var tipo = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;
                if (!vm.tarefas[tipo]) {
                    vm.tarefas[tipo] = [];
                }
                var tarefa = atividade.solicitacaoDesenvolvimento;
                var indice = vm.tarefas[tipo].map(function (value) {
                    return value.numero
                }).indexOf(tarefa.numero);


                if (indice > -1) {
                    vm.tarefas[tipo][indice].tempoGasto += atividade.tempo;
                } else {
                    tarefa.tempoGasto = atividade.tempo;
                    vm.tarefas[tipo].push(tarefa);
                    if (atividade.solicitacaoDesenvolvimento.responsavel && atividade.solicitacaoDesenvolvimento.responsavel.login === vm.filtro.user.login) {
                        vm.totalTarefasTrabalhadas = vm.totalTarefasTrabalhadas + 1;
                    } else {
                        vm.totalTarefasAjudadas = vm.totalTarefasAjudadas + 1;
                    }
                }
            } else {
            }
        }


        function recuperarHorasApontada() {
            vm.horaApontadaPorDia = [];
            var loop = new Date(vm.filtro.inicio);
            while (loop < new Date(vm.filtro.fim)) {
                var newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
                if (loop.getDay() === 6 || loop.getDay() === 0) {
                    continue;
                }
                var criadoEm = $filter('date')(newDate, 'yyyy-MM-dd');

                vm.horaApontadaPorDia.push({dia: criadoEm, tempoGasto: 0, horaPonto: 0, percentual: 0});
            }

            Chamado.queryTempoGasto(vm.filtro, function (data) {
                vm.atividades = data;
                vm.totalTarefasTrabalhadas = 0;
                vm.totalTarefasAjudadas = 0;

                vm.totalHoraTrabalhadas = 0;

                angular.forEach(vm.atividades, function (atividade) {
                    var criadoEm = $filter('date')(atividade.criadoEm, 'yyyy-MM-dd');

                    for (var i = 0; i < vm.horaApontadaPorDia.length; i++) {
                        if (vm.horaApontadaPorDia[i].dia === criadoEm) {
                            vm.horaApontadaPorDia[i].tempoGasto = vm.horaApontadaPorDia[i].tempoGasto + atividade.tempo;
                        }
                    }
                    vm.totalHoraTrabalhadas = vm.totalHoraTrabalhadas + atividade.tempo;
                    adicionarTarefa(atividade);
                });

                recuperarHorasPonto();

                SolicitacaoDesenvolvimento.getAllConcluidasSprint(vm.filtro, function (data) {
                    vm.concluidas = data;
                });

                SolicitacaoDesenvolvimento.getAllRejeitadasSprint(vm.filtro, function (data) {
                    vm.rejeitadas = data;
                });
            });
        }

        function recuperarHorasPonto() {
            Chamado.queryTempoGastoPonto(vm.filtro, function (data) {
                vm.horasPonto = data;
                angular.forEach(vm.horasPonto, function (ponto) {
                    if (ponto.horasTrabalhadas) {
                        var criadoEm = $filter('date')(ponto.data, 'yyyy-MM-dd');
                        for (var i = 0; i < vm.horaApontadaPorDia.length; i++) {
                            if (vm.horaApontadaPorDia[i].dia === criadoEm) {
                                vm.horaApontadaPorDia[i].horaPonto += timeToDecimal(ponto.horasTrabalhadasString);
                            }
                        }
                    }
                });
                calculaPercentual();
            }, function (error) {
                calculaPercentual();
            });
        }

        function calculaPercentual() {
            angular.forEach(vm.horaApontadaPorDia, function (dia) {
                var horaPonto = dia.horaPonto;
                if (horaPonto <= 0) {
                    horaPonto = 8.48;
                }
                dia.percentual = ((dia.tempoGasto * 100) / horaPonto);
            });
            montarGraficoHoras();
        }

        function montarGraficoHoras() {
            vm.labelsHoras = [];
            vm.dataHoras = [[], [], [], []];
            vm.serieHoras = ["Trabalhado", "Esperado", "Apontado", "Minimo"];
            angular.forEach(vm.horaApontadaPorDia, function (dia) {
                vm.labelsHoras.push($filter('date')(dia.dia, 'dd/MM/yyyy'));
                vm.dataHoras[0].push(dia.horaPonto);
                vm.dataHoras[1].push(8.48);
                vm.dataHoras[2].push(dia.tempoGasto);
                vm.dataHoras[3].push(0);
            });
            montarGraficoModulos();
        }

        function montarGraficoModulos() {
            vm.labelsModulo = [];
            vm.dataModulo = [];
            vm.totalModulo = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                if (vm.labelsModulo.indexOf(atividade.chamado.modulo.descricao) < 0) {
                    vm.labelsModulo.push(atividade.chamado.modulo.descricao);
                }
            });
            angular.forEach(vm.labelsModulo, function (modulo) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    if (atividade.chamado.modulo.descricao === modulo) {
                        if (!vm.dataModulo[vm.labelsModulo.indexOf(modulo)]) {
                            vm.dataModulo[vm.labelsModulo.indexOf(modulo)] = 0;
                        }
                        vm.dataModulo[vm.labelsModulo.indexOf(modulo)] =
                            vm.dataModulo[vm.labelsModulo.indexOf(modulo)] + atividade.tempo;
                        vm.totalModulo += atividade.tempo;
                    }
                });
            });
            montarGraficoTipos()
        }

        function montarGraficoTipos() {
            vm.labelsTipo = [];
            vm.dataTipo = [];
            vm.totalTipo = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var tipo = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;
                if (vm.labelsTipo.indexOf(tipo) < 0) {
                    vm.labelsTipo.push(tipo);
                }
            });
            angular.forEach(vm.labelsTipo, function (tipo) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var tipoAtividade = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;

                    if (tipoAtividade === tipo) {
                        if (!vm.dataTipo[vm.labelsTipo.indexOf(tipo)]) {
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] = 0;
                        }
                        vm.dataTipo[vm.labelsTipo.indexOf(tipo)] =
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] + atividade.tempo;
                        vm.totalTipo += atividade.tempo;
                    }
                });
            });
            montarGraficoComplexidade()
        }

        function montarGraficoComplexidade() {
            vm.labelsComplexidade = [];
            vm.dataComplexidade = [];
            vm.totalComplexidade = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var complexidade = atividade.chamado.complexidade ? $filter('translate')('chamadosApp.ComplexidadeChamado.' + atividade.chamado.complexidade) : "Sem Comple.";
                if (vm.labelsComplexidade.indexOf(complexidade) < 0) {
                    vm.labelsComplexidade.push(complexidade);
                }
            });
            angular.forEach(vm.labelsComplexidade, function (complexidade) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var complexidadeAtividade = $filter('translate')('chamadosApp.ComplexidadeChamado.' + atividade.chamado.complexidade);

                    if (complexidadeAtividade === complexidade) {
                        if (!vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)]) {
                            vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] = 0;
                        }
                        vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] =
                            vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] + atividade.tempo;
                        vm.totalComplexidade += atividade.tempo;
                    }
                });
            });
            montarGraficoProjeto();
        }


        function montarGraficoProjeto() {
            vm.labelsProjeto = [];
            vm.dataProjeto = [];
            vm.totalProjeto = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var projeto = atividade.chamado.cliente.nome;
                if (vm.labelsProjeto.indexOf(projeto) < 0) {
                    vm.labelsProjeto.push(projeto);
                }
            });
            angular.forEach(vm.labelsProjeto, function (projeto) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var projetoAtividade = atividade.chamado.cliente.nome;

                    if (projetoAtividade === projeto) {
                        if (!vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)]) {
                            vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)] = 0;
                        }
                        vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)] =
                            vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)] + atividade.tempo;
                        vm.totalProjeto += atividade.tempo;
                    }
                });
            });
        }

        function timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1] / 6) * 10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
        }

        load();

    }

})();


