(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', '$scope',
        'Chamado', 'Account', 'localStorageService', '$window', 'Notificacao',
        '$rootScope', '$uibModal', 'SolicitacaoDesenvolvimento', 'Acao', 'Ata'];

    function NavbarController($state, Auth, Principal, ProfileService, $scope,
                              Chamado, Account, localStorageService, $window, Notificacao,
                              $rootScope, $uibModal, SolicitacaoDesenvolvimento, Acao, Ata) {
        var vm = this;
        vm.login = login;
        vm.logout = logout;

        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.buscar = buscar;
        vm.getIconePorSituacao = getIconePorSituacao;
        vm.$state = $state;
        vm.textoBusca = "";
        vm.notificar = notificar;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.marcarNotificacaoComoLida = marcarNotificacaoComoLida;

        vm.estaNaTelaDeChamadosPorSituacao = estaNaTelaDeChamadosPorSituacao;
        vm.abrirJanela = abrirJanela;
        vm.abrirJanelaAcao = abrirJanelaAcao;
        vm.hasAcaoPendente = false;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

       function montarMenu(){
           vm.menu = [
               {
                   ordem: 1,
                   fila: 'ABERTO',
                   sref: 'new-chamado',
                   click: vm.collapseNavbar,
                   icon: 'fa fa-plus-circle',
                   label: 'Chamado'
               },
               {
                   ordem: 2,
                   authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                   sref: 'scrumboard',
                   click: vm.collapseNavbar,
                   icon: 'fa fa-code-fork',
                   label: 'Board'
               },
               {
                   ordem: 3,
                   authority: 'ROLE_USER',
                   dropdown: true,
                   click: vm.collapseNavbar,
                   icon: 'fa fa-list-alt',
                   label: 'Atendimento',
                   filhos: [
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                           sref: 'new-suporte',
                           icon: 'fa fa-plus-circle',
                           label: 'Atividade'
                       },
                       {
                           fila: 'ABERTO',
                           sref: 'new-chamado',
                           icon: 'fa fa-plus-circle',
                           label: 'Chamado'
                       },
                       {
                           authority: 'ROLE_USER',
                           sref: 'chamado({situacao: null})',
                           icon: 'fa fa-tasks',
                           label: 'Todos Chamados'
                       },
                       {
                           authority: 'ROLE_USER',
                           sref: 'solicitante',
                           icon: 'fa fa-user',
                           label: 'Solicitantes'
                       },
                       {
                           authority: 'ROLE_PO',
                           sref: 'aplicacao',
                           icon: 'fa fa-file-text-o',
                           label: 'Aplicações'
                       },
                       {
                           authority: 'ROLE_OCORRENCIA_CHAMADO',
                           sref: 'ocorrencia',
                           icon: 'fa fa-code',
                           label: 'Ocorrências'
                       },
                       {
                           authority: 'ROLE_USER',
                           sref: 'agrupador',
                           icon: 'fa fa-exchange',
                           label: 'Agrupador'
                       },
                       {
                           authority: 'ROLE_PO, ROLE_DESENVOLVEDOR',
                           sref: 'feedback',
                           icon: 'fa fa-feed',
                           label: 'Feedback'
                       },
                   ]
               },
               {
                   ordem: 4,
                   authority: 'ROLE_USER',
                   dropdown: true,
                   click: vm.collapseNavbar,
                   icon: 'fa fa-code-fork',
                   label: 'Desenvolvimento',
                   filhos: [
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR, ROLE_SCRUM',
                           sref: 'ata',
                           icon: 'fa fa-file',
                           label: 'Atas'
                       },
                       {
                           authority: 'ROLE_DESENVOLVEDOR',
                           sref: 'solicitacaoDesenvolvimento',
                           icon: 'fa fa-ticket',
                           label: 'Tarefas'
                       },
                       {
                           fila: 'AGUARDANDO_DESENVOLVIMENTO',
                           sref: 'planejamento',
                           icon: 'fa fa-clock-o',
                           label: 'Planejamento'
                       },
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR, ROLE_SCRUM',
                           sref: 'sprint',
                           icon: 'fa fa-code',
                           label: 'Ciclos'
                       },
                       {
                           fila: 'VALIDADO',
                           sref: 'release',
                           icon: 'fa fa-code-fork',
                           label: 'Releases'
                       },
                   ]
               },
               {
                   ordem: 5,
                   authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR, ROLE_CLIENTE',
                   dropdown: true,
                   click: vm.collapseNavbar,
                   icon: 'fa fa-sitemap',
                   label: 'Relatórios',
                   filhos: [
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                           sref: 'tempo-gasto',
                           icon: 'fa fa-clock-o',
                           label: 'Tempo Gasto'
                       },
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                           sref: 'chamados-atrasados',
                           icon: 'fa fa-check-square-o',
                           label: 'Entrega de Chamados'
                       },
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                           sref: 'acompanhamento-sprint',
                           icon: 'fa fa-group',
                           label: 'Acompanhamento dos Ciclos'
                       },
                       {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'movimentacao-demandas',
                           icon: 'fa fa-check-square-o',
                           label: 'Movimentação das Demandas'
                       },
                       {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'chamados-por-release',
                           icon: 'fa fa-check-square-o',
                           label: 'Chamados por Release'
                       },
                       {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'chamados-por-solicitante',
                           icon: 'fa fa-check-square-o',
                           label: 'Chamados por Solicitante'
                       },
                       {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'chamados-por-volumetria',
                           icon: 'fa fa-check-square-o',
                           label: 'Volumetria'
                       }, {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'chamados-por-sprint',
                           icon: 'fa fa-check-square-o',
                           label: 'Chamados por Ciclo'
                       }, {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'chamados-por-fila',
                           icon: 'fa fa-check-square-o',
                           label: 'Chamados por Fila'
                       },
                       {
                           authority: 'ROLE_PO, ROLE_PLANEJAMENTO_SPRINT, ROLE_ATENDENTE',
                           sref: 'chamados-rejeitados',
                           icon: 'fa fa-thumbs-o-down',
                           label: 'Rejeitados'
                       },
                   ]
               },
               {
                   ordem: 7,
                   authority: 'ROLE_USER',
                   dropdown: true,
                   click: vm.collapseNavbar,
                   icon: 'fa fa-user',
                   label: vm.account.firstName,
                   filhos: [
                       {
                           authority: 'ROLE_USER',
                           sref: 'settings',
                           icon: 'fa fa-gears',
                           label: 'Configurações'
                       },
                       {
                           authority: 'ROLE_USER',
                           sref: 'password',
                           icon: 'fa fa-user',
                           label: 'Senha'
                       },
                       {
                           authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                           sref: 'redmine',
                           icon: 'fa fa-git-square',
                           label: 'Git'
                       },
                       {
                           authority: 'ROLE_USER',
                           sref: 'sessions',
                           icon: 'fa fa-cloud',
                           label: 'Sessões'
                       },
                       {
                           authority: 'ROLE_USER',
                           click: vm.logout,
                           sref: 'login',
                           icon: 'fa fa-sign-out',
                           label: 'Sair'
                       },
                   ]
               },
               {
                   ordem: 6,
                   authority: 'ROLE_ATENDENTE, ROLE_PO, ROLE_DESENVOLVEDOR',
                   sref: 'wiki',
                   icon: 'fa fa-list',
                   label: 'Wiki'
               },
           ];
       }

        function abrirJanela() {
            vm.chamado = null;
            vm.solicitacaoDesenvolvimento = null;
            vm.ata = null;

            if ($state.current && $state.current.name === 'edit-chamado' && $state.params.id) {
                Chamado.get({id: $state.params.id}, function (data) {
                    vm.chamado = data;
                    abrirApontamentoHoras();
                });
            } else if ($state.current && $state.current.name === 'solicitacaoDesenvolvimento.detail' && $state.params.id) {
                SolicitacaoDesenvolvimento.get({id: $state.params.id}, function (data) {
                    vm.solicitacaoDesenvolvimento = data;
                    abrirApontamentoHoras();
                });
            } else if ($state.current && $state.current.name === 'detail-ata' && $state.params.id) {
                Ata.get({id: $state.params.id}, function (data) {
                    vm.ata = data;
                    abrirApontamentoHoras();
                });
            } else {
                abrirApontamentoHoras();
            }
        }

        function estaNaTelaDeChamadosPorSituacao(situacao) {
            return ($state.current && $state.current.name === 'chamado' && $state.params.situacao && $state.params.situacao == situacao)
        }

        function abrirApontamentoHoras() {
            $uibModal.open({
                templateUrl: 'app/entities/tempogasto/tempogasto-dialog.html',
                controller: 'TempoGastoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            chamado: vm.chamado,
                            solicitacaoDesenvolvimento: vm.solicitacaoDesenvolvimento,
                            ata: vm.ata,
                            criadoEm: new Date(),
                            horas: 0,
                            minutos: 0
                        };
                    }
                }
            })
        }

        function abrirJanelaAcao() {
            $uibModal.open({
                templateUrl: 'app/admin/acao/acao-justificativa-dialog.html',
                controller: 'AcaoJustificativaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {}
            })
        }

        function marcarNotificacaoComoLida() {
            Notificacao.marcarComoLida({}, function (data) {
                carregarNotificacoes();
            });
        }


        function notificar(id) {
            Notificacao.notificar({}, function (data) {
                carregarNotificacoes();
            });
        }

        function carregarSituacoes() {
            Chamado.contarPorSituacao({}, function (data) {
                vm.situacoes = data;
            });
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (account) {
                    Account.getImagemAjustada({}, function (data) {
                        vm.imagemUsuario = data.imagemAjustada;
                    });
                    carregarSituacoes();
                    carregarNotificacoes();
                    montarMenu();
                }
            });
        }

        getAccount();
        // carregarSituacoes();


        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });


        $rootScope.$on('chamadosApp:notificacaoUpdate', function () {
            carregarSituacoes();
            carregarNotificacoes();
        });

        $rootScope.$on('chamadosApp:acaoUpdate', function () {
            hasAcaoPendente();
        });

        $rootScope.$on('chamadosApp:activityNotification', function () {
            carregarSituacoes();
            carregarNotificacoes();
        });


        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function buscar(event) {
            event.preventDefault();
            if (vm.textoBusca) {
                $state.go("pesquisa", {search: vm.textoBusca});
                vm.textoBusca = "";
            }
        }

        function carregarNotificacoes() {
            // vm.notificacoes = Notificacao.queryNaoLidas();
            // vm.comentarios = Chamado.getComentariosMeiaHora();
        }

        function getIconePorSituacao(situacao) {
            switch (situacao.situacaoChamado) {
                case 'ABERTO':
                    return 'fa-envelope-o';
                case 'IMPEDIDO':
                    return 'fa-bell';
                case 'EM_SUPORTE':
                    return 'fa-group';
                case 'EM_ANALISE':
                    return 'fa-spinner';
                case 'EM_ANALISE_DESENVOLVIMENTO':
                    return 'fa-code';
                case 'TESTE_SUPORTE':
                    return 'fa-spinner';
                case 'EM_DESENVOLVIMENTO':
                    return 'fa-code-fork';
                case 'AGUARDANDO_TESTE':
                    return 'fa-slideshare';
                case 'AGUARDANDO_VALIDACAO':
                    return 'fa-check';
                case 'FECHADO':
                    return 'fa-chack-circle-o';
                case 'REJEITADO':
                    return 'fa-thumbs-o-down';
                case 'VALIDADO':
                    return 'fa-thumbs-o-up';
                case 'AGUARDANDO_DESENVOLVIMENTO':
                    return 'fa-laptop';
                default:
                    return 'fa-circle-o';
            }
        }

        vm.hasMiniNavbar = function () {
            return $('body').hasClass('mini-navbar');
        }

        vm.minimalize = function () {
            $("body").toggleClass("mini-navbar");
            if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
                // Hide menu in order to smoothly turn on when maximize menu
                $('#side-menu').hide();
                // For smoothly turn on menu
                setTimeout(
                    function () {
                        $('#side-menu').fadeIn(500);
                    }, 100);
            } else if ($('body').hasClass('fixed-sidebar')) {
                $('#side-menu').hide();
                setTimeout(
                    function () {
                        $('#side-menu').fadeIn(500);
                    }, 300);
            } else {
                // Remove all inline style from jquery fadeIn function to reset menu state
                $('#side-menu').removeAttr('style');
            }
        }

        function hasAcaoPendente() {
            vm.hasAcaoPendente = false;
            Acao.byUserPendente(function (data) {
                if (data != null && data.length > 0) {
                    vm.hasAcaoPendente = true;
                }
            });
        }

        hasAcaoPendente();
    }

})();
