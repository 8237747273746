angular.module('chamadosApp')
    .component('situacoesSprint', {
        bindings: {
            situacoesSprintSelecionadas: '=',
            mostrarBotaoAtribuidasParaMim: '<',
            atribuidasParaMim: '=',
            onSelecionar: '&'
        },
        templateUrl: 'app/components/situacoesSprint/situacoesSprint.component.html',
        controller: function() {
            var $ctrl = this;
            $ctrl.situacoesSprint = [null, 'ABERTA', 'EM_ANDAMENTO', 'FECHADA'];
            $ctrl.mostrarBotaoAtribuidasParaMim = $ctrl.mostrarBotaoAtribuidasParaMim !== undefined ?
                $ctrl.mostrarBotaoAtribuidasParaMim : true;

            $ctrl.isSelecionado = function(situacao) {
                return $ctrl.situacoesSprintSelecionadas.indexOf(situacao) >= 0;
            };

            $ctrl.isAtribuidasParaMim = function () {
                return $ctrl.atribuidasParaMim === true;
            }

            $ctrl.inverterAtribuidasParaMim = function () {
                $ctrl.atribuidasParaMim = !$ctrl.atribuidasParaMim;
                $ctrl.onSelecionar();
            }

            $ctrl.inverterSituacao = function (situacao) {
                var index = $ctrl.situacoesSprintSelecionadas.indexOf(situacao);
                if (index >= 0) {
                    $ctrl.situacoesSprintSelecionadas.splice(index, 1);
                } else {
                    $ctrl.situacoesSprintSelecionadas.push(situacao);
                }
                $ctrl.onSelecionar();
            };
        }
    });
