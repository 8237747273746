(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$timeout', 'Principal', '$state', 'Chamado', 'Sprint', '$sanitize', 'DateUtils', 'FeedBack', '$uibModal', 'Alerta', '$rootScope', 'SolicitacaoDesenvolvimento', 'Account', '$filter', 'Agrupador', 'Acao', '$sce'];

    function HomeController($scope, $timeout, Principal, $state, Chamado, Sprint, $sanitize, DateUtils, FeedBack, $uibModal, Alerta, $rootScope, SolicitacaoDesenvolvimento, Account, $filter, Agrupador, Acao, $sce) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = login;
        vm.register = register;
        vm.tempoExcedente = tempoExcedente;
        vm.trustAsHtml = trustAsHtml;
        vm.verChamadosProjeto = verChamadosProjeto;
        vm.labelsModulo = [];
        vm.dataModulo = [[], []];
        vm.labelsTipo = [];
        vm.dataTipo = [];
        vm.sprints = [];
        vm.alertas = [];
        vm.sprintSelecionada = null;
        vm.loadAll = loadAll;
        vm.situacoesSprintSelecionadas = [null, 'EM_ANDAMENTO'];
        vm.changeSprint = changeSprint;
        vm.changeSituacoesSprint = changeSituacoesSprint;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        function login() {
            $state.go('login');
        }

        getAccount();

        function trustAsHtml(value) {
            return $sanitize(value);
        }

        function loadSprint() {
            Sprint.query({page: 0, size: 99}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    var sprint = data[i];
                    if (sprint.situacao !== 'ABERTA') {
                        vm.sprints.push(sprint);
                    }
                }
                if (vm.sprintSelecionada === null) {
                    vm.sprintSelecionada = vm.sprints[0];
                }
                loadAll();
            });
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.filtro = {};
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.filtro.user = vm.account;
                vm.filtro.sprintAtual = true;
                loadSprint();
                buildBashboardUrl();
            });
        }

        function buildBashboardUrl() {
            var dashBoardUrl = "https://dashboard.webpublico.app.br/public/dashboard/ca4ab58c-783e-4de8-9c82-5f3230f35f72?";
            if (vm.account.modulos.length > 0) {
                dashBoardUrl += "modulo=";
                for (var i = 0; i < vm.account.modulos.length; i++) {
                    var modulo = vm.account.modulos[i];
                    dashBoardUrl += modulo.id+ (i < vm.account.modulos.length -1?  ", ": "");
                }
            }
            if (vm.account.equipes.length > 0) {
                dashBoardUrl += "&equipe=";
                for (var x = 0; x < vm.account.equipes.length; x++) {
                    var equipe = vm.account.equipes[x];
                    dashBoardUrl += equipe.id + (x < vm.account.equipes.length -1?  ", ": "");
                }
            }
            vm.dashBoardUrl = $sce.trustAsResourceUrl(dashBoardUrl);
        }

        function register() {
            $state.go('register');
        }

        function loadAgrupadores() {
            Agrupador.getBySituacao({}, function (data) {
                vm.agrupadores = data;
            });
        }

        function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        function contarChamadosPorSituacao(situacao, cor) {
            if (!vm.clientesChamado) {
                vm.clientesChamado = {};
            }
            vm.clientesChamado[situacao] = [];
            if (!vm.tiposChamado) {
                vm.tiposChamado = {};
            }
            vm.tiposChamado[situacao] = {};
            if (!vm.modulosChamado) {
                vm.modulosChamado = {};
            }
            vm.modulosChamado[situacao] = {};
            if (!vm.situacoesDosChamado) {
                vm.situacoesDosChamado = [];
            }
            Chamado.contarByFiltro({situacao: situacao}, function (data) {
                var chamadosContados = data;
                vm.situacoesDosChamado.push({situacao: situacao, quantidade: chamadosContados.length, cor: cor});
                for (var j = 0; j < chamadosContados.length; j++) {
                    if (!vm.tiposChamado[situacao][chamadosContados[j].tipoChamado]) {
                        vm.tiposChamado[situacao][chamadosContados[j].tipoChamado] = {
                            quantidade: 0, nome: chamadosContados[j].tipoChamado
                        };
                    }
                    vm.tiposChamado[situacao][chamadosContados[j].tipoChamado].quantidade += chamadosContados[j].quantidade;

                    if (!vm.modulosChamado[situacao][chamadosContados[j].modulo]) {
                        vm.modulosChamado[situacao][chamadosContados[j].modulo] = {
                            quantidade: 0, nome: chamadosContados[j].modulo, id: chamadosContados[j].moduloId
                        };
                    }
                    vm.modulosChamado[situacao][chamadosContados[j].modulo].quantidade += chamadosContados[j].quantidade;

                    var cliente = {
                        nome: chamadosContados[j].cliente, id: chamadosContados[j].clienteId, totalItems: 0
                    };
                    var achou = false;
                    for (var i = 0; i < vm.clientesChamado[situacao].length; i++) {
                        if (vm.clientesChamado[situacao][i].id === cliente.id) {
                            cliente = vm.clientesChamado[situacao][i];
                            achou = true;
                            break;
                        }
                    }
                    if (!achou) {
                        vm.clientesChamado[situacao].push(cliente);
                    }
                    cliente.totalItems += chamadosContados[j].quantidade;
                }
            });
        }

        function buscarTarrefasDaSprint() {
            var consulta = {
                idUsuario: vm.filtro.user.id,
                situacoesSprint: vm.situacoesSprintSelecionadas
            }
            SolicitacaoDesenvolvimento.getAllEmDesenvolvimento(consulta,
                function (data) {
                vm.tarefasDaSprint = data;
                vm.tarefasRejeitadas = [];
                vm.tarefasFazendo = [];
                vm.tarefasImpedidas = [];
                vm.tarefasAtrasadas = [];
                for (var i = 0; i < data.length; i++) {
                    var tarefa = data[i];
                    if (tarefa.situacao === 'FAZENDO') {
                        vm.tarefasFazendo.push(tarefa);
                    }
                    if (tarefa.situacao === 'REJEITADO') {
                        vm.tarefasRejeitadas.push(tarefa);
                    }
                    if (tarefa.situacao === 'IMPEDIDO') {
                        vm.tarefasImpedidas.push(tarefa);
                    }
                    if (tarefa.sprintOriginal !== vm.sprintSelecionada.descricao && tarefa.sprintChamado) {
                        vm.tarefasAtrasadas.push(tarefa);
                    }
                }
                if (vm.tarefasFazendo.length > 1) {
                    vm.alertas.push({
                        tipo: 'warning',
                        title: vm.tarefasFazendo.length + ' Tarefas em situação de FAZENDO ao mesmo tempo'
                    });
                }
                if (vm.tarefasRejeitadas.length > 0) {
                    vm.alertas.push({
                        tipo: 'danger',
                        titulo: vm.tarefasRejeitadas.length + ' Tarefa(s) Rejeitada(s)',
                        link: 'scrum-board'
                    });
                }
                if (vm.tarefasAtrasadas.length > 0) {
                    vm.alertas.push({
                        tipo: 'danger',
                        titulo: vm.tarefasAtrasadas.length + ' Tarefa(s) Atrasadas(s)',
                        link: 'scrum-board'
                    });
                }
                if (vm.tarefasImpedidas.length > 0) {
                    vm.alertas.push({
                        tipo: 'warning',
                        titulo: vm.tarefasImpedidas.length + ' Tarefa(s) Impedidas(s)',
                        link: 'scrum-board'
                    });
                }
            });
        }

        function loadAcaoPendente() {
            Acao.byUserPendente(function (data) {
                vm.acoes = data;
                if (data != null && data.length > 0) {
                    vm.alertas.push({
                        tipo: 'danger', titulo: vm.acoes.length + ' Ações não Justificadas', link: 'scrum-board'
                    });
                }
            });
        }

        function recuperarTickets() {
            vm.tarefas = {};
            vm.todasTarefas = [];

            vm.chamados = [];
            vm.horasPorModulo = [];
            vm.horasPonto = [];
            vm.atividades = [];
            if (!vm.filtro.user.imagem) {
                Account.getImagemAjustadaByLogin({login: vm.filtro.user.login}, function (data) {
                    vm.filtro.user.imagem = data.imagemAjustada;
                });
            }

        }


        function recuperarHorasApontada() {
            vm.horaApontadaPorDia = [];
            var loop = new Date(vm.sprintSelecionada.inicio);
            while (loop < new Date(vm.sprintSelecionada.fim)) {
                var newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
                if (loop.getDay() === 6 || loop.getDay() === 0) {
                    continue;
                }
                var criadoEm = $filter('date')(newDate, 'yyyy-MM-dd');

                vm.horaApontadaPorDia.push({dia: criadoEm, tempoGasto: 0, horaPonto: 0, percentual: 0});
            }

            Chamado.queryTempoGasto({
                inicio: vm.sprintSelecionada.inicio,
                fim: vm.sprintSelecionada.fim,
                user: vm.filtro.user
            }, function (data) {
                vm.atividades = data;
                vm.totalTarefasTrabalhadas = 0;
                vm.totalTarefasAjudadas = 0;

                vm.totalHoraTrabalhadas = 0;

                angular.forEach(vm.atividades, function (atividade) {
                    var criadoEm = $filter('date')(atividade.criadoEm, 'yyyy-MM-dd');

                    for (var i = 0; i < vm.horaApontadaPorDia.length; i++) {
                        if (vm.horaApontadaPorDia[i].dia === criadoEm) {
                            vm.horaApontadaPorDia[i].tempoGasto = vm.horaApontadaPorDia[i].tempoGasto + atividade.tempo;
                        }
                    }
                    vm.totalHoraTrabalhadas = vm.totalHoraTrabalhadas + atividade.tempo;
                    adicionarTarefa(atividade);
                });

                recuperarHorasPonto();

                SolicitacaoDesenvolvimento.getAllConcluidasSprint(vm.filtro, function (data) {
                    vm.concluidas = data;
                });

                SolicitacaoDesenvolvimento.getAllRejeitadasSprint(vm.filtro, function (data) {
                    vm.rejeitadas = data;
                });
                montarGraficoModulos();
            });
        }

        function recuperarHorasPonto() {
            vm.filtro.inicio = vm.sprintSelecionada.inicio;
            vm.filtro.fim = vm.sprintSelecionada.fim;
            Chamado.queryTempoGastoPonto(vm.filtro, function (data) {
                vm.horasPonto = data;
                angular.forEach(vm.horasPonto, function (ponto) {
                    if (ponto.horasTrabalhadas) {
                        var criadoEm = $filter('date')(ponto.data, 'yyyy-MM-dd');
                        for (var i = 0; i < vm.horaApontadaPorDia.length; i++) {
                            if (vm.horaApontadaPorDia[i].dia === criadoEm) {
                                vm.horaApontadaPorDia[i].horaPonto += timeToDecimal(ponto.horasTrabalhadasString);
                            }
                        }
                    }
                });
                calculaPercentual();
            }, function (error) {
                calculaPercentual();
            });
        }

        function calculaPercentual() {
            angular.forEach(vm.horaApontadaPorDia, function (dia) {
                var horaPonto = dia.horaPonto;
                if (horaPonto <= 0) {
                    horaPonto = 8.48;
                }
                dia.percentual = ((dia.tempoGasto * 100) / horaPonto);
            });
        }


        function timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1] / 6) * 10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
        }

        function changeSprint() {
            loadAll();
        }

        function changeSituacoesSprint() {
            loadAll();
        }

        function loadAll() {
            vm.alertas = [];
            vm.labelsModulo = [];
            vm.dataModulo = [[], []];
            vm.labelsTipo = [];
            vm.dataTipo = [];

            Chamado.getChamadosSprintAtual({sprintId: vm.sprintSelecionada.id}, function (chamados) {
                vm.sprintEmAndamento = {};
                vm.sprintEmAndamento.chamados = chamados;
                vm.sprintEmAndamento.tempoPlanejado = 0;
                if (chamados && chamados.length > 0) {
                    vm.sprintEmAndamento.horasDisponiveisSprint = chamados[0].horasDisponiveisSprint;
                    vm.sprintEmAndamento.inicioSprint = DateUtils.convertLocalDateFromServer(chamados[0].inicioSprint);
                    vm.sprintEmAndamento.fimSprint = DateUtils.convertLocalDateFromServer(chamados[0].fimSprint);
                    vm.sprintEmAndamento.sprint = chamados[0].sprint;

                    angular.forEach(vm.sprints, function (sprint) {
                        if (chamados[0].sprint === sprint.descricao) {
                            vm.sprintSelecionada = sprint;
                        }
                    });
                }

                vm.chamadosPorCliente = {};
                vm.chamadosPorSistema = {};
                vm.chamadosPorModulo = {};
                vm.chamadosPorTipo = {};

                angular.forEach(vm.sprintEmAndamento.chamados, function (chamado) {
                    if (!vm.chamadosPorCliente[chamado.clienteId]) {
                        vm.chamadosPorCliente[chamado.clienteId] = {
                            cliente: chamado.cliente,
                            clienteId: chamado.clienteId,
                            disponivel: chamado.horasDisponiveisSprint,
                            alocado: 0,
                            suporte: 0,
                            chamados: []
                        };
                    }
                    if (!vm.chamadosPorModulo[chamado.modulo]) {
                        vm.chamadosPorModulo[chamado.modulo] = {
                            modulo: chamado.modulo,
                            alocado: 0,
                            suporte: 0,
                            chamados: []
                        };
                    }
                    if (!vm.chamadosPorTipo[chamado.tipoChamado]) {
                        vm.chamadosPorTipo[chamado.tipoChamado] = {
                            tipo: chamado.tipoChamado,
                            alocado: 0,
                            suporte: 0,
                            chamados: []
                        };
                    }
                    if (!vm.chamadosPorSistema[chamado.sistema]) {
                        vm.chamadosPorSistema[chamado.sistema] = {
                            sistema: chamado.sistema, alocado: 0, suporte: 0, chamados: []
                        };
                    }
                    vm.chamadosPorCliente[chamado.clienteId].chamados.push(chamado);
                    vm.chamadosPorSistema[chamado.sistema].chamados.push(chamado);
                    vm.chamadosPorModulo[chamado.modulo].chamados.push(chamado);
                    vm.chamadosPorTipo[chamado.tipoChamado].chamados.push(chamado);

                    if (chamado.tipoChamado !== 'SUPORTE') {
                        vm.sprintEmAndamento.tempoPlanejado = vm.sprintEmAndamento.tempoPlanejado + chamado.tempoEstimado;
                        vm.chamadosPorCliente[chamado.clienteId].alocado += chamado.tempoEstimado;
                        vm.chamadosPorSistema[chamado.sistema].alocado += chamado.tempoEstimado;
                        vm.chamadosPorModulo[chamado.modulo].alocado += chamado.tempoEstimado;
                    } else {
                        vm.chamadosPorCliente[chamado.clienteId].suporte += chamado.tempoEstimado;
                        vm.chamadosPorSistema[chamado.sistema].suporte += chamado.tempoEstimado;
                        vm.chamadosPorModulo[chamado.modulo].suporte += chamado.tempoEstimado;
                    }
                    vm.chamadosPorTipo[chamado.tipoChamado].alocado += chamado.tempoEstimado;
                    if (vm.labelsModulo.indexOf(chamado.modulo) < 0) {
                        vm.labelsModulo.push(chamado.modulo);
                    }
                    if (vm.labelsTipo.indexOf(chamado.tipoChamado) < 0) {
                        vm.labelsTipo.push(chamado.tipoChamado);
                    }
                });

                angular.forEach(vm.labelsModulo, function (modulo) {
                    vm.dataModulo[0].push(0);
                    vm.dataModulo[1].push(0);
                    angular.forEach(vm.sprintEmAndamento.chamados, function (chamado) {
                        if (chamado.modulo === modulo) {
                            vm.dataModulo[0][vm.labelsModulo.indexOf(modulo)] = vm.dataModulo[0][vm.labelsModulo.indexOf(modulo)] + chamado.tempoEstimado;
                        }
                    });
                });

                angular.forEach(vm.labelsTipo, function (tipo) {
                    vm.dataTipo.push(0);
                    angular.forEach(vm.sprintEmAndamento.chamados, function (chamado) {
                        if (chamado.tipoChamado === tipo) {
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] = vm.dataTipo[vm.labelsTipo.indexOf(tipo)] + chamado.tempoEstimado;
                        }
                    });
                });
                vm.labelsModulo.push('Total');
                vm.dataModulo[0].push(vm.sprintEmAndamento.tempoPlanejado);

                var inicio = vm.sprintEmAndamento.inicioSprint;
                inicio.setHours(0, 0, 0, 0);
                var hoje = new Date();
                hoje.setHours(0, 0, 0, 0);
                vm.totalDias = 0;
                vm.diasPercorridos = 0;
                while (inicio < vm.sprintEmAndamento.fimSprint) {
                    vm.totalDias++;
                    if ((inicio.getDate() === hoje.getDate())) {
                        vm.diasPercorridos = vm.totalDias;
                    }
                    inicio = addDays(inicio, 1);
                }
                if (vm.diasPercorridos === 0) {
                    vm.diasPercorridos = vm.totalDias;
                }

                recuperarHorasApontada();

                if (Principal.hasAnyAuthority(['ROLE_DESENVOLVEDOR', 'ROLE_PO', 'ROLE_ATENDENTE'])) {
                    buscarTarrefasDaSprint();
                    vm.mostraColunaDetalhesUsuario = true;
                }
                if (Principal.hasAnyAuthority(['ROLE_PO'])) {
                    contarChamadosPorSituacao('EM_DESENVOLVIMENTO', 'success');
                }
                if (Principal.hasAnyAuthority(['ROLE_PO', 'ROLE_ATENDENTE'])) {
                    loadAgrupadores();
                    contarChamadosPorSituacao('TESTE_SUPORTE', 'warning');
                }
                if (Principal.hasAnyAuthority(['ROLE_CLIENTE'])) {
                    loadAgrupadores();
                    contarChamadosPorSituacao('AGUARDANDO_TESTE', 'danger');
                    contarChamadosPorSituacao('AGUARDANDO_VALIDACAO', 'warning');
                }
                if (Principal.hasAnyAuthority(['ROLE_DESENVOLVEDOR', 'ROLE_PO', 'ROLE_ATENDENTE'])) {
                    loadAcaoPendente();
                    recuperarTickets();
                }
            });
        }

        function tempoExcedente(cliente) {
            var tempo = cliente.alocado - cliente.disponivel;
            return tempo;
        }

        function adicionarTarefa(atividade) {
            if (atividade.solicitacaoDesenvolvimento) {
                if (atividade.tipo === 'CODE_REVIEW') {
                    return;
                }
                var tem = false;
                for (var i = 0; i < vm.tarefasDaSprint.length; i++) {
                    var tarefaDaSprint = vm.tarefasDaSprint[i];
                    if (tarefaDaSprint.id === atividade.solicitacaoDesenvolvimento.id) {
                        tem = true;
                        if (!tarefaDaSprint.tempoGasto) {
                            tarefaDaSprint.tempoGasto = 0;
                        }
                        tarefaDaSprint.tempoGasto += atividade.tempo;
                        break;
                    }
                }
                var tipo = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;
                if (!vm.tarefas[tipo]) {
                    vm.tarefas[tipo] = [];
                }
                var tarefa = atividade.solicitacaoDesenvolvimento;
                var indice = vm.tarefas[tipo].map(function (value) {
                    return value.numero;
                }).indexOf(tarefa.numero);


                if (indice > -1) {
                    vm.tarefas[tipo][indice].tempoGasto += atividade.tempo;
                } else {
                    tarefa.tempoGasto = atividade.tempo;
                    vm.tarefas[tipo].push(tarefa);
                    if (atividade.solicitacaoDesenvolvimento.responsavel && atividade.solicitacaoDesenvolvimento.responsavel.login === vm.filtro.user.login) {
                        vm.totalTarefasTrabalhadas = vm.totalTarefasTrabalhadas + 1;
                    } else {
                        vm.totalTarefasAjudadas = vm.totalTarefasAjudadas + 1;
                    }
                }
            } else {
            }
        }


        function verChamadosProjeto(cliente) {
            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'chamadosCliente.html',
                controller: 'DialogChamadosProjeto',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    cliente: vm.chamadosPorCliente[cliente.clienteId], feedBack: {}
                }
            });
            modalInstance.result.then(function (feedBack) {
                FeedBack.save(feedBack, function (data) {
                    vm.feedBack = data;
                    Alerta.info("Obrigado!", "");
                });
            });
        }

        function montarGraficoModulos() {
            vm.labelsModulo = [];
            vm.dataModulo = [];
            vm.totalModulo = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                if (vm.labelsModulo.indexOf(atividade.chamado.modulo.descricao) < 0) {
                    vm.labelsModulo.push(atividade.chamado.modulo.descricao);
                }
            });
            angular.forEach(vm.labelsModulo, function (modulo) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    if (atividade.chamado.modulo.descricao === modulo) {
                        if (!vm.dataModulo[vm.labelsModulo.indexOf(modulo)]) {
                            vm.dataModulo[vm.labelsModulo.indexOf(modulo)] = 0;
                        }
                        vm.dataModulo[vm.labelsModulo.indexOf(modulo)] =
                            vm.dataModulo[vm.labelsModulo.indexOf(modulo)] + atividade.tempo;
                        vm.totalModulo += atividade.tempo;
                    }
                });
            });
            montarGraficoTipos();
        }

        function montarGraficoTipos() {
            vm.labelsTipo = [];
            vm.dataTipo = [];
            vm.totalTipo = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var tipo = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;
                if (vm.labelsTipo.indexOf(tipo) < 0) {
                    vm.labelsTipo.push(tipo);
                }
            });
            angular.forEach(vm.labelsTipo, function (tipo) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var tipoAtividade = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;

                    if (tipoAtividade === tipo) {
                        if (!vm.dataTipo[vm.labelsTipo.indexOf(tipo)]) {
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] = 0;
                        }
                        vm.dataTipo[vm.labelsTipo.indexOf(tipo)] =
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] + atividade.tempo;
                        vm.totalTipo += atividade.tempo;
                    }
                });
            });
            montarGraficoComplexidade();
        }

        function montarGraficoComplexidade() {
            vm.labelsComplexidade = [];
            vm.dataComplexidade = [];
            vm.totalComplexidade = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var complexidade = atividade.chamado.complexidade ? $filter('translate')('chamadosApp.ComplexidadeChamado.' + atividade.chamado.complexidade) : "Sem Comple.";
                if (vm.labelsComplexidade.indexOf(complexidade) < 0) {
                    vm.labelsComplexidade.push(complexidade);
                }
            });
            angular.forEach(vm.labelsComplexidade, function (complexidade) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var complexidadeAtividade = $filter('translate')('chamadosApp.ComplexidadeChamado.' + atividade.chamado.complexidade);

                    if (complexidadeAtividade === complexidade) {
                        if (!vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)]) {
                            vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] = 0;
                        }
                        vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] =
                            vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] + atividade.tempo;
                        vm.totalComplexidade += atividade.tempo;
                    }
                });
            });
            montarGraficoProjeto();
        }


        function montarGraficoProjeto() {
            vm.labelsProjeto = [];
            vm.dataProjeto = [];
            vm.totalProjeto = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var projeto = atividade.chamado.cliente.nome;
                if (vm.labelsProjeto.indexOf(projeto) < 0) {
                    vm.labelsProjeto.push(projeto);
                }
            });
            angular.forEach(vm.labelsProjeto, function (projeto) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var projetoAtividade = atividade.chamado.cliente.nome;

                    if (projetoAtividade === projeto) {
                        if (!vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)]) {
                            vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)] = 0;
                        }
                        vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)] =
                            vm.dataProjeto[vm.labelsProjeto.indexOf(projeto)] + atividade.tempo;
                        vm.totalProjeto += atividade.tempo;
                    }
                });
            });
        }

    }

    angular
        .module('chamadosApp')
        .controller('DialogChamadosProjeto', DialogChamadosProjeto);

    DialogChamadosProjeto.$inject = ['$uibModalInstance', 'cliente'];


    function DialogChamadosProjeto($uibModalInstance, cliente) {
        var vm = this;
        vm.feedBack = cliente;
        vm.cliente = cliente;
        vm.ok = function () {
            $uibModalInstance.close();
        };

    };

})();


